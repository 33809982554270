.about-container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 20px;;
}

.about-container strong {
  font-size: 20px;
  line-height: 26px;
}

.about-container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.about-container a {
  text-decoration: none;
}