.summary ion-grid {
    margin-top: 0;
    padding: 0;
}

ion-card.summary {
    --background: #fff;
    --color: #000;
}

.cta ion-grid {
    margin-top: 0;
    padding: 0;
}

ion-card.cta {
    --background: #fff;
    --color: #000;
}

.cta ion-item {
    --background: #fff;
    --color: #000;
    --border-radius: 10px;
    --border-color: #474747;
    --border-style: solid;
    --border-width: 1px;
    font-size: smaller;
}

.fullbutton {
    text-align: center;
}

.cta ion-avatar {
    margin-inline: 0px 10px;
}

.cta ion-card-content {
    padding: 0;
    margin: 0;
}

.cta ion-button {
    --background: #b9b9b9;
    --background-hover: #b9b9b9;
    --background-activated: #b9b9b9;
    --background-focused: #b9b9b9;
  
    --color: rgb(0, 0, 0);
  
    
  
    --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
  
    --ripple-color: deeppink;
  
    --padding-top: 10px;
    --padding-bottom: 10px;
  }

.callHelp {
    font-weight: bold;
    font-size: 27px !important;
}

.summary ion-card-title {
    --color: #fff;
    font-weight: bold;
    text-align: center;
}

.summary ion-card-subtitle {
    --color: rgb(0, 0, 0);
    text-align: center;
}

.summary ion-card-header {
    margin: 0;
    padding: 20px;
    --background: rgb(223, 223, 223);
}

.summary ion-card-header.ios {
    display: flex;
    flex-flow: column-reverse;
}

.summary ion-img::part(image) {
    border-radius: 50% !important;
}

ion-card.eventRegData {
    --background: #fff;
    --color: #000;
    margin-top: 10px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2px;

}

.eventRegData ion-card-title {
    --color: var(--ion-color-primary);
    font-weight: bold;
    text-align: center;
    font-size: medium;
}

.eventRegData ion-card-header {
    margin: 0;
    padding: 10px;
    --background: rgb(223, 223, 223);
}

.eventRegData ion-card-content {
    padding: 0px;
}

.eventRegData ion-label {
    font-size: smaller !important;
    white-space: wrap !important;
    inline-size: 50px !important;
    overflow-wrap: break-word !important;
}

ion-toast.lastRefresh {
    --background: var(--toast-color);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #000000;
  }

.goalBox {
    text-align: center;
}

.progressBar {
    max-width: 70px;
    display: block;
    margin: auto;
}

.greeting {
    color: rgb(124, 124, 124);
    font-weight: bold;
    font-size: large;
    text-align: center;
    padding-bottom: 0;
}

.presented {
    color: rgb(0, 0, 0);
    font-weight: bold;
    text-align: center;
}

.eventHistory {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    padding-top: 0;
}

.totalStats {
    text-align: center;
}

.optionLabel {
    font-weight: bold;
    text-align: left;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.optionValue {
    text-align: left;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 0 !important;
    padding-bottom: 10px !important;
}

.sponsorLogo {
    max-width: 100px;
    display: block;
    margin: auto;
    padding-top: 15px;
}



/*  2023-03-23  Shrink the size of card when on larger displays */
@media screen and (min-width: 600px) {
    ion-card.summary {
        width: 50%;
        top: 5%;
        margin: auto;
    }
}
