ion-content.login {
  --color: #000000;
  /*background: url(../../public/assets/img/loginbackground.jpeg);*/
  --background: none;
  /*background-image: url(https://photos.smugmug.com/photos/i-4kD9BrH/0/4K/i-4kD9BrH-4K.jpg);*/
  background: url(../../public/assets/img/LoginBackground.jpg);
  background-position: 20% 40%;
  background-repeat: no-repeat;
  background-size: cover;
}

/*  2023-04-07  hmw changes to fields */
.login ion-item {
  margin-bottom: 1rem !important;
  border-radius: 10px;
}
.error{
  margin-bottom: 1rem !important;
}

.custom-button{
  --color: #ffffff !important;
  /* --background: var(--pmc-red); */
  font-weight: bold;
}

.login a {
  color: var(--pmc-red);
  text-decoration: none;
}

toast-message a {
  color: #ffffff;
}

ion-toast.custom-toast {
  --background: #c30000;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #ffffff;
}

ion-toast.custom-toast::part(button) {
  border-left: 1px solid #f30000;
  color: #ffffff;
  font-size: 15px;
}

ion-toast.custom-toast::part(message) {
  color: #ffffff;
  font-weight: bold;
}

ion-toast::part(header) {
  font-weight: bold;
}

.login ion-grid{
  --background: none;
  background: rgba(255, 255, 255, 0.85);
  min-height: fit-content;
  margin-top: 50px;
}

.version {
  color: #868686;
}

@media only screen and (min-width : 320px){
  .login ion-grid{
      max-width: 370px;
      border-radius: 10px;
  }    
}

@media only screen and (min-width : 768px) {
  .login ion-grid{
      max-width: 650px;
      border-radius: 20px;
  }
}
