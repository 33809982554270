.ContactList ion-grid {
    margin-top: 0;
    padding: 0;
}



.ContactList ion-avatar {
    border-radius: 50% !important;
    /*background-color: rgb(204, 204, 204);*/
    /*color: white;*/
    /*margin: auto;*/
    margin: 0;
    padding: 0;
}

.md .nameColumn {
    padding-left: 20px;
}


.ContactList .name {
    font-weight: bold;
}
