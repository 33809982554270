.registerModal ion-grid {
    padding: 0px !important;
    margin: 0px !important;
}

.registerModal .regHeader {
    padding-top: 20px;
    font-weight: bolder;
    font-size: larger;
}

.registerModal ion-button {
    display: block;
    margin: auto;
    color: white;
}

.registerModal img {
    max-width: 200px;
    display: block;
    margin: auto;
}
