.homecontainer {
  text-align: center;
  left: 0;
  right: 0;
}

.homecontainer strong {
  font-size: 20px;
  line-height: 26px;
}

.homecontainer p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

